import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
import Realisation from "../../components/realisation"

class Cosmos extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="La roue de la fortune" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-cosmos inuit-box inuit-box--flush">
            <h2 className="hero__message hero__message--bigger hero__message--center">
                </h2>
            </div>

            <article className="article project inuit-u-p0 ">

                <div className="article__body article__body--has-extra ">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="http://bosch.com/" title="Programme EXTRA!" target="_blank">
                                <img src="/img/clients/cosmos.jpg" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">COSMOS ELECTRO</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://cosmos.utopie.ma/" title="JEU CONCOUR" target="_blank">JEU
                                    CONCOURS</a>
                            </span>
                            <span className="project__detail">Since 2017</span>
                            
                        </p>
                        <p className="article__para inuit-u-p0">
                       

                            <video className=" hero__video--in-cassette " style={{ zIndex: "10000" }} autoPlay loop muted>
                                <source src="/video/cosmos-300x250.mp4" type="video/mp4" ></source>
                            </video>
                                
                        </p>
                    </aside>

                    <h1 className="article__heading m-top">Cosmos : La roue de la fortune</h1>
                    <p className="article__para ">
                        Faites tourner la roue pour gagner : un jeu de hasard qui n’en est pas vraiment un ! La roue a été programmée pour faire gagner aux participants une remise de 5 % ou de 6 %. Le gagnant reçoit son bon de réduction directement sur sa boîte mail.  <br />

                    </p>
                   
                </div>





               


             
                

                <p className="article__belt inuit-u-mt0 mb-0">
                    <img className="article__belt-image w-70" src="/img/projects/cosmos/macbook-rou.jpg" alt="mobile-apps" />
                </p>

                <p className="article__belt inuit-u-mt0 mb-0">
                    <img className="article__belt-image w-70" src="/img/projects/cosmos/cosmos-steps.jpg" alt="mobile-apps" />
                </p>

                

               

                <p className="call-to-action hero__message customized-call-to-action">
                    <a href="https://cosmos.utopie.ma" target="_blank" className="call-to-action__link">
						FAITES TOURNER LA ROUE
                    </a>
				</p>

                <br /><br />

                {/* <div className="article__body">
                    <h2 className="article__heading">Tester le jeu sur la recette :)</h2>
                    <p className="article__para">
                        <a href="https://cosmos.utopie.ma" target="_blank"> <img src="/img/projects/cosmos/cosmos-play.jpg" />
                        </a>
                        <br /><br />
                        Vous avez eu 5% ou 6%? Ce n'est pas que vous n'avez pas de chance ni par hasard :D
                        <br /><br />
                    </p>
                </div> */}
                
            </article>
            <Realisation/>

            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default Cosmos
