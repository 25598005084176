// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const ProjectCube = ({ title, action, image }) => (
	<li className="gallery__item">
        <a 
            href={action}
            rel="noopener noreferrer"
            className="gallery__link"
        >
            <span 
                className="gallery__attention-text" 
                dangerouslySetInnerHTML={{ __html: title }} 
            />
            <img src={image} alt="cover" className="gallery__img" />
        </a>
    </li>
)

ProjectCube.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  action: PropTypes.string,
}

ProjectCube.defaultProps = {
  title: ``,
  image: ``,
  action: ``,
}

export default ProjectCube
